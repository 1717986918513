import * as React from "react"
import Image from "../modules/imageComponent";

export default class Investors extends React.Component {
    render() {
        return <div className={'investors'}>
            <div className={'row align-items-center'}>
                <div className={'col col-12 col-md-4 col-lg-3 border-lg-right text-center text-lg-left'}>
                    <h5>Investors</h5>
                </div>
                <div className={'col col-12 col-md-8 col-lg-9'}>
                    <div className={'row align-items-center justify-content-start'}>
                        <div className={'col col-6 col-md-3 text-center py-3'}><Image alt={'Dragonfly Capital'} filename={'investors/dragonfly.svg'} /></div>
                        <div className={'col col-6 col-md-2 text-center py-3'}><Image alt={'Interchain Foundation'} filename={'investors/interchain.svg'} /></div>
                        <div className={'col col-6 col-md-3 text-center py-3'}><Image alt={'Informal'} filename={'investors/informal.svg'} /></div>
                        <div className={'col col-6 col-md-2 text-center py-3'}><Image alt={'Lemniscap'} filename={'investors/lemniscap.svg'} /></div>
                        <div className={'col col-6 col-md-2 text-center py-3'}><Image alt={'Volt Capital'} filename={'investors/volt-capital.svg'} /></div>
                        <div className={'col col-6 col-md-3 text-center py-3'}><Image alt={'Robot Ventures'} filename={'investors/robot.svg'} /></div>
                        <div className={'col col-6 col-md-2 text-center py-3'}><Image alt={'Figment'} filename={'investors/figment.png'} /></div>
                        <div className={'col col-6 col-md-3 text-center py-3'}><Image alt={'ZKValidator'} filename={'investors/zk.svg'} /></div>
                        <div className={'col col-12 col-md-2 text-center py-3'}><Image alt={'Strangelove Ventures'} filename={'investors/strangelove.png'} /></div>
                    </div>
                </div>
            </div>
        </div>
    }
}
