import * as React from "react"
import Layout from "../components/layout"
import Button from "../modules/button";
import TechnologyBox from "../modules/technologyBox";
import Image from "../modules/imageComponent";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Investors from "../modules/investors";

import mainAnim1 from "../images/animations/main_icon_1/animation";
import mainAnim2 from "../images/animations/main_icon_2/animation";
import mainAnim3 from "../images/animations/main_icon_3/animation";
import mainAnim4 from "../images/animations/main_icon_4/animation";


import video from "../video/header.mp4"
import { graphql } from "gatsby";

const IndexPage = ({
    data: {
        allMarkdownRemark: { edges },
    },
}) => {
    const Posts = edges
        .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
        .map(edge => <div className={'row mt-5'}>
            <div className={'col-12'}>
                <AniLink fade duration={0.1} hex={'#000000'} to={edge.node.frontmatter.slug}>
                    <div className={'row article align-items-center'}>
                        <div className={'col col-12 col-md-6'}>
                            <div className={'image'}>
                                <Image alt={edge.node.frontmatter.title} filename={edge.node.frontmatter.image} />
                            </div>
                        </div>
                        <div className={'col col-12 col-md-6'}>
                            <div className={'category'}>{edge.node.frontmatter.category}</div>
                            <h3>{edge.node.frontmatter.title}</h3>
                            {edge.node.frontmatter.description}
                            <div className={'button small'}>Read more</div>
                        </div>
                    </div>
                </AniLink>
            </div>
        </div>)

    return (
        <Layout className={'home'} title={'Home'}>
            <section className={'header-section'}>
                <div className={'container'}>
                    <div className={'animation'} />
                    <div className={'video'}>
                        <video autoPlay muted loop playsInline id="main-video">
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                    <div className={'text'}>
                        <h1>Private Trading in Any Cryptoasset</h1>
                        <div className={'subtitle'}>Penumbra is a shielded, cross-chain network allowing anyone to securely transact, stake, swap, or marketmake without broadcasting their personal information to the world.</div>
                        <Button url={'https://discord.gg/hKvkrqa3zC'} title={'Join Discord'} target={'_blank'} />
                    </div>
                </div>
            </section>
            <section className={'technology-section'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col col-12 col-md-6'}>
                            <TechnologyBox animation={mainAnim1} image={'technology/transact.svg'} class={'transact'} url={'technology/transact'} title={'Transact'} text={'Natively cross-chain, with shielded transactions in any cryptoasset.'} />
                        </div>
                        <div className={'col col-12 col-md-6'}>
                            <TechnologyBox animation={mainAnim3} image={'technology/stake.svg'} class={'stake'} url={'technology/stake'} title={'Stake'} text={'The world\'s first shielded proof-of-stake network, with privacy for delegators and accountability for validators.'} />
                        </div>
                        <div className={'col col-12 col-md-6'}>
                            <TechnologyBox animation={mainAnim2} image={'technology/swap.svg'} class={'swap'} url={'technology/swap'} title={'Swap'} text={'Shielded swaps between any kind of cryptoasset, with batch execution and no frontrunning.'} />
                        </div>
                        <div className={'col col-12 col-md-6'}>
                            <TechnologyBox animation={mainAnim4} image={'technology/marketmake.svg'} class={'marketmake'} url={'technology/marketmake'} title={'Marketmake'} text={'Concentrated liquidity positions can be managed privately, protecting marketmakers\' active strategies.'} />
                        </div>
                    </div>
                </div>
            </section>
            <section className={'roadmap-section'} id={'roadmap'}>
                <div className={'container'}>
                    <h2 className={'color'}>Network Launch</h2>
                    <h3>Penumbra is currently live on Mainnet.</h3>
                    <p>When assets enter penumbra, they are no longer visible to anyone other than the asset holder.
                        Your privacy starts and ends with a user's wallet. Download the newest version of the Prax
                        wallet <a style={{color: "#99f"}} target={'_blank'}
                                  href="https://chromewebstore.google.com/detail/prax-wallet/lkpmkhpnhknhmibgnmmhdhgdilepfghe">here</a>.
                    </p>
                    <p>Asset holders on any IBC network can now shield liquidity in Penumbra's shielded pool to
                        privately swap any compatible token, stake, and transact.</p>
                    <a style={{color: "#99f"}} target={'_blank'}
                       href="https://stake.with.starlingcyber.net/#/ibc">Shield your assets into Penumbra now</a>
                </div>
            </section>
            <section className={'newsfeed-section'}>
                <div className={'container'}>
                    <h2 className={'color'}>Newsfeed</h2>

                    {Posts}

                </div>
            </section>
            <section className={'investors-section'}>
                <div className={'container'}>
                    <Investors />
                </div>
            </section>
        </Layout>
    )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 3) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
            image
            category
          }
        }
      }
    }
  }
`
