import * as React from "react"
import Image from "../modules/imageComponent";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Lottie from "react-lottie-player";
import handleViewport from "react-in-viewport";

class Tech extends React.Component {
    getInViewPort() {
        const { inViewport, enterCount, leaveCount } = this.props;
        let counter = enterCount - leaveCount;
        if (inViewport && counter === 1) {
            return true;
        }else {
            return false;
        }
    }
    render() {
        return <div>
            <AniLink fade duration={0.1} hex={'#000000'}
                to={this.props.url}>
            <div className={'technology '+this.props.class }>
                <div className={'bg'}/>
                <div className={'overlay'}/>
                <div className={'inner'}>
                    <div className={'image'}>
                        {this.getInViewPort() ? <Lottie animationData={this.props.animation} play loop={false}/> : <Lottie animationData={this.props.animation} loop={false}/>}
                    </div>
                    <div className={'text'}>
                        <div className={'title'}>{this.props.title}</div>
                        <div className={'text'}>{this.props.text}</div>
                        <div className={'button small'}>Read more</div>
                    </div>
                </div>
            </div>
            </AniLink>
        </div>
    }
}


const TechnologyBox = handleViewport(Tech, { rootMargin: '-1.0px', threshold: 0 });

export default TechnologyBox;
