import * as React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default class Button extends React.Component {
    render() {
        return <AniLink duration={0.1} fade to={this.props.url} className={'button '+this.props.class}>
            <div className={'title'}>{this.props.title}</div>
        </AniLink>
    }
}
